import { useNavigate } from "react-router-dom";
import { Search } from "../../../../../assets/js/scripts";

export default function SearchAdd() {
    const navigate = useNavigate();
    const addFinding = () => { navigate("/addFinding") }

    return (
        <div className="w3-row">
            <div className="w3-col s4 search">
                <input className="w3-input w3-border w3-round" type="text" placeholder="Search..." autoComplete="off" 
                    id="search" onKeyUp={Search}/>
            </div>
            <div className="w3-col s4 w3-right">
                <button onClick={addFinding} className="w3-button w3-indigo w3-padding-small w3-right w3-round w3-small">Add</button>
            </div>
        </div>
    );
}