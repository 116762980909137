import { Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { convert } from "../../../../assets/js/scripts";
import Spinner from '../../../../assets/imgs/spinner.gif';

export default function FindingDetails(props) {
    const { finding, editRow, loading } = props;
    const navigate = useNavigate();
    const goBack = () => { navigate("/dashboard") }

    return (
        <Fragment>
            {finding.length > 0 ? (
                finding.map((item) => (
                    <div key={item.id} className="w3-content w3-padding-16 w3-animate-zoom">
                        <div className="w3-half">
                            <div className="w3-row-padding">
                                <label>Description</label>
                                <textarea className="w3-input w3-border" defaultValue={item.description} disabled ></textarea>
                            </div>
                        </div>
                        <div className="w3-half">
                            <div className="w3-row-padding">
                                <div className="w3-half">
                                    <label>Date Carried</label>
                                    <input className="w3-input w3-border" type="date" defaultValue={convert(item.date_carried)} disabled />
                                </div>
                                <div className="w3-half">
                                    <label>Level</label>
                                    <input className="w3-input w3-border" type="text" defaultValue={item.finding_level} disabled />
                                </div>
                            </div>
                            <div className="w3-row-padding">
                                <div className="w3-half">
                                    <label>Due Date</label>
                                    <input className="w3-input w3-border" type="date" defaultValue={item.due_date} disabled />
                                </div>
                                <div className="w3-half">
                                    <label>Status</label>
                                    <input className="w3-input w3-border" type="text" defaultValue={item.finding_remarks} disabled />
                                </div>
                            </div>
                            <div className="w3-row-padding">
                                <div className="w3-col w3-center">
                                    <button type="button" className="w3-button w3-blue w3-round addPartBtn" onClick={() => { editRow(item) }}>
                                        Edit Finding
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="w3-left w3-col">
                            <hr />
                            <button type="button" onClick={goBack} className="w3-button w3-white w3-border w3-border-blue w3-round addPartBtn">
                                Go Back
                            </button>
                        </div>
                    </div>
                ))
            ) : (loading ?
                <div className="w3-center w3-col" style={{ textAlign: 'center', padding: '100px' }}>
                    <img src={Spinner} alt="Loading..." />
                </div> :
                <div className="w3-center w3-col" style={{ textAlign: 'center', padding: '100px' }}>
                    <span>{finding.error || "An error occured while loading data."}</span>
                    <br />
                    <br />
                    <button type="button" onClick={goBack} className="w3-button w3-blue w3-round">
                        Go Back
                    </button>
                </div>
            )}
        </Fragment >
    );
}