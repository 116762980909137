import instance from './baseURL';

//Authentication
export const login = (data) => {
  return instance.post('/v1/authenticate', data).then(({ data }) => {
    instance.defaults.headers.common['Authorization'] = data.token;
    return data
  })
    .catch(err => {
      throw new Error(err)
    })
}