import jwt_decode from "jwt-decode";
import { redirectUser } from './redirect';
import { Fragment, useState } from 'react';
import { useNavigate } from "react-router-dom";
import * as Api from "../../../api/authenticate";
import useForm from "../../../components/hooks/useForm";

function SignInForm() {
    const navigate = useNavigate();
    const [connenction, setConnenction] = useState('');
    const initialFormState = { username: '', password: '' }
    const { values: user, isSubmitting, error: errors, isLoading, setError, setIsLoading,
        handleChange, handleSubmit } = useForm(initialFormState, login);

    function login() {
        const loginData = { username: user.username, password: user.password }
        Api.login(loginData).then((res) => {
            setError(res);
            setConnenction(false);
            setIsLoading(false);
            const token = res.token;
            if (token) {
                const user = jwt_decode(token);
                redirectUser(user, navigate)
            }
        })
            .catch((error) => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                setConnenction(resMessage)
                setIsLoading(false);
                setError(false);
                if (resMessage === 'Error: Network Error') {
                    setConnenction('Error! Please check your internet connenction.')
                }
            })
    }
    const handleMsg = (error) => {
        return (error.error) ? (<div className="w3-panel w3-pale-yellow"><p>{error.error}</p></div>)
            : (error.status) ? (<div className="w3-panel w3-pale-blue"><p>{error.status}</p></div>)
            : (connenction) ? (<div className="w3-panel w3-pale-red"><p>{connenction}</p></div>)
            : null
    };
    
    return (
        <Fragment>
            <form noValidate onSubmit={handleSubmit} autoComplete="off">
                <div className="w3-col">
                    <input type="text" name="username" value={user.username} placeholder="Email"
                        onChange={handleChange} className="w3-input" disabled={isLoading} />
                    {(isLoading ? (isSubmitting && errors.username && (<div className="w3-small w3-text-red">{null}</div>)) :
                        (errors.username && ((<div className="w3-small w3-text-red">{errors.username}</div>)))
                    )}
                </div>
                <div className="w3-col">
                    <input type="password" name="password" value={user.password} placeholder="Password"
                        onChange={handleChange} className="w3-input" disabled={isLoading}  />
                    {(isLoading ? (isSubmitting && errors.password && (<div className="w3-small w3-text-red">{null}</div>)) :
                        (errors.password && ((<div className="w3-small w3-text-red">{errors.password}</div>)))
                    )}
                </div>
                <div className="w3-col w3-center">
                    <button type="submit" disabled={isLoading} className="w3-button w3-blue loginBtn" >
                        {isLoading ? (<span>Authenticating...</span>) : (<span>Sign in</span>)}
                    </button>
                </div>
                <br/>
                <div className="w3-col" style={{marginTop: '-10px'}}>
                    {isLoading ? null : (handleMsg(errors))}
                </div>
            </form>
        </Fragment>
    );
}

export default SignInForm;