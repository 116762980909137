import instance from './baseURL'

export const add = (data) => {
    return instance.post('/v1/finding', data).then(({ data }) => data).catch(err => {
        throw new Error(err)
    })
}
export const update = (id, data) => {
    return instance.put(`/v1/finding/${id}`, data).then(({ data }) => data).catch(err => {
        throw new Error(err)
    })
}
export const remove = (id) => {
    return instance.delete(`/v1/finding/${id}`).then(({ data }) => data).catch(err => {
        throw new Error(err)
    })
}