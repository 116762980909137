import SignInForm from "./form";
import { Fragment } from "react";
import { Helmet } from 'react-helmet';

export default function SignIn() {
    return (
        <Fragment>
            <Helmet>
                <title> Sign in </title>
                <meta name="description" content="Audit Report" />
            </Helmet>
            <div className="w3-container centered login">
                <div className="w3-container w3-text-dark-gray">
                    <h2 className="w3-large w3-center"><b>Sign In </b></h2>
                </div>
                <SignInForm />
            </div>
        </Fragment>
    );
}