/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import instance from '../../api/baseURL';

const useAxios = (axiosParams) => {
    const [error, setError] = useState('');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async (params) => {
        setIsLoading(true);
        let isSubscribed = true;
        try {
            const result = await instance.request(params);
            if (isSubscribed) {
                setData(result.data);
                setIsLoading(false);
            }
        } catch (error) {
            if (isSubscribed) {
                setError(error);
                setIsLoading(false);
            }
        } finally {
            setIsLoading(false);
        }
        return () => (isSubscribed = false);
    };

    useEffect(() => {
        fetchData(axiosParams);
    }, []);

    return { data, error, isLoading, setData, setError };
};

export default useAxios;