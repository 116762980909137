import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

export default function MenuHeader(props) {
    const { user, isLoading } = props;
    const navigate = useNavigate();
    function logOut() {
        localStorage.removeItem("user");
        navigate("/");
    }
    
    return (
        <Fragment>
            {user.length > 0 ? (
                user.map((item) => (
                    <div key={item.id} className="w3-black">
                        <span className="w3-bar-item w3-left" style={{ padding: "7px 0 0 10px" }}>Aircare Avionics Comm. Ltd.</span>
                        <div className="w3-dropdown-hover w3-right">
                            <button className="w3-button">{item.name}</button>
                            <div className="w3-dropdown-content w3-bar-block w3-border" style={{ right: "0" }}>
                                <span onClick={logOut} className="w3-bar-item w3-button">Logout</span>
                            </div>
                        </div>
                        <div className="w3-clear"></div>
                    </div>
                ))
            ) : (isLoading ?
                <div className="w3-black">
                    <span className="w3-bar-item w3-left" style={{ padding: "7px 0 0 10px" }}>Aircare Avionics Comm. Ltd.</span>
                    <div className="w3-dropdown-hover w3-right">
                        <button className="w3-button">User name</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border" style={{ right: "0" }}>
                            <span onClick={logOut} className="w3-bar-item w3-button">Logout</span>
                        </div>
                    </div>
                    <div className="w3-clear"></div>
                </div>
                :
                <div className="w3-black">
                    <span className="w3-bar-item w3-left" style={{ padding: "7px 0 0 10px" }}>Aircare Avionics Comm. Ltd.</span>
                    <div className="w3-dropdown-hover w3-right">
                        <button className="w3-button">Error loading user</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border" style={{ right: "0" }}>
                            <span onClick={logOut} className="w3-bar-item w3-button">Logout</span>
                        </div>
                    </div>
                    <div className="w3-clear"></div>
                </div>
            )}
        </Fragment>
    );
}