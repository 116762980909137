import { Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import * as Api from "../../../../../api/findings";

export default function ActionMenu(props) {
    const { row, findings, setError, setData } = props;
    const navigate = useNavigate();
    const editRecord = () => { navigate(`/editFinding/${row.id}`) }
    const deleteRecord = (id) => {
        const display = window.confirm("Are you sure you want to delete this record?");
        if (display) {
            Api.remove(id).then((res) => {
                setError(res)
                setData(findings.filter((item) => item.id !== id));
            });
            // alert("Record deleted");
            return true;
        } else {
            alert("Record not deleted");
            return false;
        }
    };

    return (
        <Fragment>
            <div className="w3-show-inline-block">
                <div className="w3-bar">
                    <button className="w3-button w3-padding-small w3-small" onClick={editRecord}
                        style={{ background: "#f1f1f1" }}>Edit
                    </button>
                    <button className="w3-button w3-padding-small w3-small" onClick={() => { deleteRecord(row.id)}}
                        style={{ background: "#eee" }}>Del
                    </button>
                </div>
            </div>
            {/* <div className="w3-dropdown-hover">
                <button className="w3-button w3-padding-small w3-small w3-white" style={{ fontWeight: "bolder" }}>&#xFE19;</button>
                <div className="w3-dropdown-content w3-bar-block w3-border" style={{ right: "0", minWidth: '100px' }}>
                    <span className="w3-bar-item w3-button w3-padding-small" onClick={editRecord}>Edit</span>
                    <span className="w3-bar-item w3-button w3-padding-small" onClick={() => { deleteRecord(row.id)}}>Del</span>
                </div>
            </div> */}
        </Fragment>
    );
}