import { Fragment } from "react";
import { Helmet } from 'react-helmet';
import ReportsTable from './tables/reportsTable';
import useAxios from "../../../components/hooks/useAxios";
import MenuHeader from '../../../components/ui/menuHeader';

const loggedUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

export default function Dashboard() {
    const userId = loggedUser().id;
    const { data: user, isLoading} = useAxios(`/v1/user/${userId}`);
    const { data: findings, isLoading: loading, setError, setData, error:errors } = useAxios(`/v1/findings`);

    return (
        <Fragment>
            <Helmet>
                <title> Dashboard </title>
                <meta name="description" content="Audit Report" />
            </Helmet>
            <MenuHeader user={user} isLoading={isLoading} />
            <div className="w3-container w3-padding-32">
                <h2 className="w3-xlarge w3-center">Audit Reports</h2>
                <hr />
                <ReportsTable findings={findings} loading={loading} setError={setError} setData={setData} errors={errors} />
            </div>
        </Fragment>
    );
}