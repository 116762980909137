import { Fragment } from "react";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";

function NotFoundPage() {
  const navigate = useNavigate();
  const goBack = () => { navigate("/dashboard") }

  return (
    <Fragment>
      <Helmet>
        <title> 404 </title>
        <meta name="description" content="Audit Report" />
      </Helmet>
      <div className="w3-container centered login">
        <div className="w3-container w3-text-dark-gray">
          <h2 className="w3-large w3-center"><b>UH OH! You're lost. </b></h2>
        </div>
        <span className="text-muted">
          The page you are looking for does not exist. How you got here is a
          mystery. <br />
          But you can use the button below to navigate back to safe harbor.
        </span>
        <br />
        <br />
        <div className="w3-center">
          <button onClick={goBack} className="w3-button w3-green w3-round">
            Home
          </button>

        </div>


      </div>
    </Fragment>
  );
}

export default NotFoundPage;
