import { useNavigate } from "react-router-dom";
import * as Api from "../../../../api/findings";
import useForm from "../../../../components/hooks/useForm";
import SuccessAlert from '../tables/components/successAlert';
import { DueDate, FindingStatus } from "../../../../assets/js/scripts";
import { initialFormState, findingLevels, findingRemarks, findingDetails } from "./formData";

export default function AddFindingForm() {
    const navigate = useNavigate();
    const { values: finding, isSubmitting, error: errors, isLoading, setIsLoading, setValues,
        handleSubmit, handleChange, setError } = useForm(initialFormState, addFinding);

    function addFinding() {
        Api.add(findingDetails(finding, DueDate(finding))).then((res) => {
            setError(res);
            setIsLoading(false);
            const msg = res.success;
            if (msg) {
                setValues(initialFormState);
            }
        })
    }
    const handleMsg = (error) => {
        return (error.success) ? (<SuccessAlert errors={errors} />) : null
    };

    const goBack = () => { navigate("/dashboard") }

    return (
        <div className="w3-content w3-padding-16 w3-animate-zoom">
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <div className="w3-half">
                    <div className="w3-row-padding">
                        <label>Description</label>
                        <textarea placeholder="Remember, be nice!" className="w3-input w3-border" name="description"
                            value={finding.description} onChange={handleChange} disabled={isLoading} >
                        </textarea>
                        {(isLoading ? (isSubmitting && errors.description && (<div className="w3-small w3-text-red">{null}</div>)) :
                            (errors.description && ((<div className="w3-small w3-text-red">{errors.description}</div>)))
                        )}
                    </div>
                </div>
                <div className="w3-half">
                    <div className="w3-row-padding">
                        <div className="w3-half">
                            <label>Date Carried</label>
                            <input className="w3-input w3-border" type="date" name="date_carried" value={finding.date_carried}
                                onChange={handleChange} disabled={isLoading} />
                            {(isLoading ? (isSubmitting && errors.date_carried && (<div className="w3-small w3-text-red">{null}</div>)) :
                                (errors.date_carried && ((<div className="w3-small w3-text-red">{errors.date_carried}</div>)))
                            )}
                        </div>
                        <div className="w3-half parts">
                            <label>Level</label>
                            <select className="w3-select w3-border" name="finding_level" value={finding.finding_level}
                                onChange={handleChange} disabled={isLoading} >
                                <option >Select option</option>
                                {findingLevels.map((item) => (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                            {(isLoading ? (isSubmitting && errors.finding_level && (<div className="w3-small w3-text-red">{null}</div>)) :
                                (errors.finding_level && ((<div className="w3-small w3-text-red">{errors.finding_level}</div>)))
                            )}
                        </div>
                    </div>
                    <div className="w3-row-padding">
                        <div className="w3-half">
                            <label>Due Date</label>
                            <input className="w3-input w3-border" type="text" name="due_date" value={DueDate(finding)} onChange={handleChange} disabled />
                        </div>
                        <div className="w3-half">
                            <label>Days to go</label>
                            <input className="w3-input w3-border" type="text" name="status" value={FindingStatus(finding)} onChange={handleChange} disabled />
                        </div>
                    </div>
                    <div className="w3-row-padding">
                        <div className="w3-half parts">
                            <label>Status</label>
                            <select className="w3-select w3-border" name="finding_remarks" value={finding.finding_remarks}
                                onChange={handleChange} disabled={isLoading} >
                                <option>Select option</option>
                                {findingRemarks.map((item) => (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                            {(isLoading ? (isSubmitting && errors.finding_remarks && (<div className="w3-small w3-text-red">{null}</div>)) :
                                (errors.finding_remarks && ((<div className="w3-small w3-text-red">{errors.finding_remarks}</div>)))
                            )}
                        </div>
                        <div className="w3-half w3-center">
                            <button type="submit" disabled={isLoading} className="w3-button w3-indigo w3-round addPartBtn">
                                {isLoading ? (<span>Submitting...</span>) : (<span>Add Finding</span>)}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <div className="w3-left w3-col">
                <hr />
                <div className="w3-col">
                    {isLoading ? null : (handleMsg(errors))}
                </div>
                <button type="button" onClick={goBack} className="w3-button w3-white w3-border w3-border-blue w3-round addPartBtn">
                    Go back
                </button>
            </div>
        </div>
    );
}