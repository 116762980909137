import { useState } from 'react';

const useForm = (initialValues, callback) => {
    const [values, setValues] = useState(initialValues);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = (event) => {
        setIsLoading(true);
        setIsSubmitting(true);
        event.preventDefault();
        callback();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    };

    return { handleChange, handleSubmit, setIsLoading, setError, setValues, values, isSubmitting, isLoading, error }
};

export default useForm;