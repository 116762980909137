export const initialFormState = () => ({
	id: null,
	description: "",
	date_carried: "",
	finding_level: "",
	due_date: "",
	status: "",
	finding_remarks: "",
});
export const findingDetails = (finding, DueDate) => ({
	description: finding.description,
	date_carried: finding.date_carried,
	finding_level: finding.finding_level,
	due_date: DueDate,
	finding_remarks: finding.finding_remarks,
})
export const editFindingDetails = (finding, DueDate) => ({
	id: finding.id,
	description: finding.description,
	date_carried: finding.date_carried,
	finding_level: finding.finding_level,
	due_date: DueDate,
	finding_remarks: finding.finding_remarks,
})
export const findingLevels = [
	{ value: "Level 1", label: 'Level 1' },
	{ value: "Level 2", label: 'Level 2' },
	{ value: "Level 3", label: 'Level 3' }
];
export const findingRemarks = [
	{ value: 'Open', label: 'Open' },
	{ value: 'Closed', label: 'Closed' }
];
