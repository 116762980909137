import { useState } from "react";

export default function SuccessAlert(props) {
    const { errors } = props;
    const [open, setOpen] = useState(true);
    const closeAlert = () => { setOpen(false) };

    return (
        open ?
            <div className="w3-panel w3-pale-green centered w3-animate-top" style={{ position: "absolute", top: '75px', width: 'auto' }} >
                <span onClick={closeAlert} className="w3-button w3-small w3-display-topright w3-padding-small">&times;</span>
                <p className="w3-padding-small">{errors.success}</p>
            </div>
            : null
    );
}