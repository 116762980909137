export function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
  // return [day, mnth, date.getFullYear()].join("-");
}
export function dateFormat(str) {
  const d = new Date(str);
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  const converted = `${da}-${mo}-${ye}`;
  return converted;
}
export function DueDate(finding) {
  const date_carried = new Date(finding.date_carried);
  const finding_level = finding.finding_level;
  const setDuration = 'Not set';
  if (finding_level === 'Level 1') {
    date_carried.setDate(date_carried.getDate() + 7);
  }
  else if (finding_level === 'Level 2') {
    date_carried.setMonth(date_carried.getMonth() + 1);
  }
  else if (finding_level === 'Level 3') {
    date_carried.setMonth(date_carried.getMonth() + 3);
  }
  else {
    return setDuration;
  }
  const expiry_date = date_carried;
  const convertedExpDate = convert(expiry_date);
  if (convertedExpDate === 'NaN-aN-aN') {
    return setDuration;
  }
  return convertedExpDate;
}
export function FindingStatus(finding) {
  const finding_level = finding.finding_level;
  const setStatus = 'Not set';
  if (finding_level === 'Level 1') {
    return '7 days remaining';
  }
  else if (finding_level === 'Level 2') {
    return '30 days remaining';
  }
  else if (finding_level === 'Level 3') {
    return '90 days remaining';
  }
  else {
    return setStatus;
  }
}
export function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}
export function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
export function changeColor(difference) {
  return difference <= 14 && difference >= 7 ? '#00c853'
    : difference <= 7 && difference > 3 ? 'orange'
    : difference <= 3 ? '#F53063'
    : 'inherit';
}
//Search
export function Search() {
  var input, filter, table, tr, i;
  var td_1, td_1Value;
  var td_2, td_2Value;
  var td_3, td_3Value;
  var td_4, td_4Value;

  input = document.getElementById("search");
  filter = input.value.toUpperCase();
  table = document.getElementById("myTable");
  tr = table.getElementsByTagName("tr");

  for (i = 0; i < tr.length; i++) {
    td_1 = tr[i].getElementsByTagName("td")[0];
    td_2 = tr[i].getElementsByTagName("td")[1];
    td_3 = tr[i].getElementsByTagName("td")[2];
    td_4 = tr[i].getElementsByTagName("td")[3];

    if (td_1 || td_2 || td_3 || td_4) {
      td_1Value = td_1.textContent || td_1.innerText;
      td_2Value = td_2.textContent || td_2.innerText;
      td_3Value = td_3.textContent || td_3.innerText;
      td_4Value = td_4.textContent || td_4.innerText;

      if (td_1Value.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else if (td_2Value.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else if (td_3Value.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else if (td_4Value.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
}