import "./assets/css/App.css";
import { Fragment } from "react";
import ReactDOM from 'react-dom';
// import reportWebVitals from './reportWebVitals';
import AppRoutes from "./components/routes/Routes";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <Fragment>
        <AppRoutes />
      </Fragment>
    </Router>
  );
}
ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
