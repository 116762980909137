import PrivateRoute from "./PrivateRoute";
import { Routes, Route } from 'react-router-dom';
import NotFoundPage from "../../pages/public/404_error/NotFound";
// Routes
import SignIn from "../../pages/public/login/loginPage";
import DashboardPage from "../../pages/private/dashboard/dashboard";
import AddFindingPage from "../../pages/private/dashboard/addFinding";
import EditFindingPage from "../../pages/private/dashboard/EditFinding";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/dashboard" element={<PrivateRoute> <DashboardPage /> </PrivateRoute>} />
      <Route path="/addFinding" element={<PrivateRoute> <AddFindingPage /> </PrivateRoute>} />
      <Route path="/editFinding/:findingId" element={<PrivateRoute> <EditFindingPage /> </PrivateRoute>} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
export default AppRoutes;
