import { useNavigate } from "react-router-dom";
import SearchAdd from './components/searchAdd';
import ActionMenu from './components/actionMenu';
import Spinner from '../../../../assets/imgs/spinner.gif';
import SuccessAlert from '../tables/components/successAlert';
import { dateFormat, dateDiffInDays, changeColor } from "../../../../assets/js/scripts";

export default function ReportsTable(props) {
    const { findings, loading, setError, setData, errors } = props;
    const navigate = useNavigate();
    const length = findings.length;

    function findingStatus(endDate) {
        const dateCarried = new Date();
        const dueDate = new Date(endDate);
        const difference = parseInt(dateDiffInDays(dateCarried, dueDate));
        return dateCarried > dueDate ? 'Over Due'
            : difference === 1 ? difference + ' day to go'
                : difference + ' days to go';
    }
    function findingColor(endDate) {
        const dateCarried = new Date();
        const dueDate = new Date(endDate);
        const difference = parseInt(dateDiffInDays(dateCarried, dueDate));
        return changeColor(difference);
    }
    const handleMsg = (error) => {
        return (error.success) ? (<SuccessAlert errors={errors} />) : null
    };

    const addFinding = () => { navigate("/addFinding") }

    return (
        <div className="w3-content w3-padding-16">
            <SearchAdd />
            <div className="div"></div>
            <table className="w3-table w3-bordered stock fixed_header" id="myTable">
                <thead >
                    <tr className="w3-dark-grey">
                        <th>Findings</th>
                        <th className="w3-center">Date Carried</th>
                        <th className="w3-center">Level</th>
                        <th className="w3-center">Due Date</th>
                        <th className="w3-center">Days to go</th>
                        <th className="w3-center">Status</th>
                        <th className="w3-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {findings.length > 0 ? (
                        findings.map((row) => (
                            <tr key={row.id}>
                                <td className="w3-padding-small">{row.description}</td>
                                <td className="w3-center w3-padding-small" >{dateFormat(row.date_carried)}</td>
                                <td className="w3-center w3-padding-small" >{row.finding_level}</td>
                                <td className="w3-center w3-padding-small" >{dateFormat(row.due_date)}</td>
                                <td className="w3-center w3-padding-small" style={{
                                    color: row.finding_remarks === "Closed" ? 'inherit' : findingColor(row.due_date)
                                }}>
                                    {row.finding_remarks === "Closed" ? 'Closed' : findingStatus(row.due_date)}
                                </td>
                                <td className="w3-center w3-padding-small" style={{
                                    color: row.finding_remarks === "Open" ? '#F53063' : 'inherit'
                                }}>{row.finding_remarks}
                                </td>
                                <td className="w3-center w3-padding-small" >
                                    <ActionMenu row={row} findings={findings} setError={setError} setData={setData} />
                                </td>
                            </tr>
                        ))
                    ) : (loading ?
                        <tr style={{ border: 'none' }}>
                            <td className="w3-center" colSpan={7} style={{ textAlign: 'center', padding: '100px', width: '1000px' }}>
                                <img src={Spinner} alt="Loading..." />
                            </td>
                        </tr>
                        :
                        <tr style={{ border: 'none' }}>
                            <td className="w3-center" colSpan={7} style={{ textAlign: 'center', padding: '100px', width: '1000px' }}>
                                {findings.error || "Network error. Failed to load data."}
                                <br />
                                <br />
                                <button onClick={addFinding} className="w3-button w3-green w3-round">
                                    Add Finding
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div className="w3-left w3-col">
                <div className="w3-col w3-padding-small">
                    {loading ? null : <span style={{ fontSize: '14px', color: '#888', }}>Records: {length}</span>}
                    {loading ? null : (handleMsg(errors))}
                </div>
            </div>
        </div>
    );
}