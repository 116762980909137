import { Helmet } from 'react-helmet';
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import EditFindingForm from './forms/editFinding';
import FindingDetails from './forms/findingDetails';
import { DueDate } from "../../../assets/js/scripts";
import useAxios from "../../../components/hooks/useAxios";
import MenuHeader from '../../../components/ui/menuHeader';
import { initialFormState, editFindingDetails } from "./forms/formData";

const loggedUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

export default function EditFinding() {
    const { findingId } = useParams();
    const userId = loggedUser().id;
    const { data: user, isLoading } = useAxios(`/v1/user/${userId}`);
    const { data: finding, isLoading: loading, setData } = useAxios(`/v1/finding/${findingId}`);

    const [currentFinding, setCurrentFinding] = useState(initialFormState);
    const [editing, setEditing] = useState(false);

    const updateFinding = (id, updatedFinding) => {
        setEditing(true);
        setData(finding.map((finding) => (finding.id === id ? updatedFinding : finding)));
    };
    const editRow = (finding) => {
        setEditing(true);
        setCurrentFinding(editFindingDetails(finding, DueDate(finding)));
    };

    return (
        <Fragment>
            <Helmet>
                <title> Edit Finding </title>
                <meta name="description" content="Audit Report" />
            </Helmet>
            <MenuHeader user={user} isLoading={isLoading} />
            <div className="w3-container w3-padding-32">
                <h2 className="w3-xlarge w3-center">Edit Finding</h2>
                <hr />
                {editing ? (
                    <EditFindingForm setEditing={setEditing} currentFinding={currentFinding} updateFinding={updateFinding} />
                ) : (
                    <FindingDetails finding={finding} editRow={editRow} loading={loading} />
                )}
            </div>
        </Fragment>
    );
}