import { Fragment } from "react";
import { Helmet } from 'react-helmet';
import AddFindingForm from './forms/addFinding';
import useAxios from "../../../components/hooks/useAxios";
import MenuHeader from '../../../components/ui/menuHeader';

const loggedUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

export default function AddFinding() {
    const userId = loggedUser().id;
    const { data: user, isLoading} = useAxios(`/v1/user/${userId}`);

    return (
        <Fragment>
            <Helmet>
                <title> Add Finding </title>
                <meta name="description" content="Audit Report" />
            </Helmet>
            <MenuHeader user={user} isLoading={isLoading} />
            <div className="w3-container w3-padding-32">
                <h2 className="w3-xlarge w3-center">Add Finding</h2>
                <hr />
                <AddFindingForm />
            </div>
        </Fragment>
    );
}